import React, {useEffect} from 'react';
import './ResetLinkSent.scss';
import IcResetLinkSent from '@icons/ic-reset-link-sent.svg';

function ResetLinkSent() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div
      id="reset-link-sent"
      className="container py-5 d-flex flex-column align-items-center justify-content-center">
      <img src={IcResetLinkSent} />
      <div className="dosis-title-1 text-philadelphia-blue mb-2">
        Reset Link Sent
      </div>
      <div className="asap-body">
        We have sent the link to your e-mail address. Check it now.
      </div>
    </div>
  );
}

export default ResetLinkSent;
